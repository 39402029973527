import requestAPI from '../requestBeApi.js'

const storeAPI = {
  postStore(payload) {
    const url = `/v1/merchants/add-store/`

    return requestAPI({
      method: 'POST',
      url,
      payload,
    })
  },

  getBrands() {
    const url = `/v1/merchants/brands/`

    return requestAPI({
      method: 'GET',
      url,
    })
  },
}

export default storeAPI
